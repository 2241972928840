import React from "react";
import './hero.css'
import img1 from'../../../assets/imgs/beacWindow.png'
import img2 from'../../../assets/imgs/cityMountain.png'
import img3 from'../../../assets/imgs/cityWindow.png'
import imgClouds from '../../../assets/imgs/clouds.png'
import AnchorLink from "react-anchor-link-smooth-scroll";

import "animate.css/animate.min.css"; 
import { AnimationOnScroll } from 'react-animation-on-scroll'; 

const Hero = () => {

return(
    <div  id='emptyLegs' className="hero-wrapper">
        <img src= {imgClouds} alt='?' className="hero-image"></img>
        <div className="text-wrapper">
            <h1 className="h1-hero">Where are we </h1>
            <h1 className="h1-outlined">going?</h1>
           
        </div>
        
        
        <AnchorLink href="#contactUs" className="image-wrapper" >
            <AnchorLink href="#contactUs"  className='image1' ></AnchorLink>
            <AnchorLink href="#contactUs" className='image2' ></AnchorLink>
            <AnchorLink  href="#contactUs" className='image3' ></AnchorLink>
        </ AnchorLink>
      
    </div>

)
}
export default Hero