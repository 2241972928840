import React from "react";
import './aircrafts.css'
import Small from "./Small";
import Medium from "./Medium";
import Large from "./Large"
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useState } from "react";
 import "animate.css/animate.min.css"; 
 import { AnimationOnScroll } from 'react-animation-on-scroll'; 

const Aircrafts = () =>  {
    const [small, setSmall] = useState(2);
    const [smallCurrent, setSmallCurrent] = useState('nav-button')
    const [mediumCurrent, setMediumCurrent] = useState('nav-button')
    const [largeCurrent, setLargeCurrent] = useState('nav-button-pressed')

    const smallAircrafts = () => {
        setSmall(0)
        setLargeCurrent('nav-button')
        setMediumCurrent('nav-button')
        setSmallCurrent('nav-button-pressed')
        console.log('small')

    }
    const mediumAircrafts = () => {
        setSmall(1)
        setLargeCurrent('nav-button')
        setMediumCurrent('nav-button-pressed')
        setSmallCurrent('nav-button')
        console.log('medium')

    }

    const largeAircrafts = () => {
        setSmall(2)
        setLargeCurrent('nav-button-pressed')
        setMediumCurrent('nav-button')
        setSmallCurrent('nav-button')
        console.log('medium')

    }
    

    return(

        
        <AnimationOnScroll animateIn="animate__fadeInLeft" id='aircrafts'className="aircraft-wrapper">
            <div className="aircrafts-text-wrapper">
                <h1 className="h1-aircrafts-outlined"> Our</h1>
                <h1 className="h1-aircrafts"> Aircrafts</h1>                
            </div>
            <div className="aircrafts-horizontal-nav">

                    <AnchorLink href="#aircrafts" className={smallCurrent} >
                        <button className={smallCurrent} onClick={smallAircrafts} >
                            Small
                        </button>
                    </AnchorLink>

                    <AnchorLink href="#aircrafts" className={mediumCurrent} >
                        <button className={mediumCurrent} onClick={mediumAircrafts}>
                            Medium
                        </button>
                    </AnchorLink>

                    <AnchorLink href="#aircrafts" className={largeCurrent} >
                        <button className={largeCurrent} onClick={largeAircrafts}>
                            Large
                        </button>
                    </AnchorLink>
            </div>
                {small === 0 && 
                    <Small></Small>
                } 
                {small === 1 && 
                    <Medium></Medium>
                } 
                {small === 2 && 
                    <Large></Large>
                } 
           
           
   

        </AnimationOnScroll>
    )
}
export default Aircrafts