import React from "react";
import Hero from "../../Components/Home/Hero";
import BookNow from "../../Components/Home/BookNow"
import AboutUs from "../../Components/Home/AboutUs";
import NavBar from "../../Components/Structural/NavBar";
import Aircrafts from "../../Components/Home/Aircrafts";
import Footer from '../../Components/Structural/Footer'


const HomePage = () => {
    return (
      
        <>
            <NavBar></NavBar>
            <Hero></Hero>
            <AboutUs></AboutUs>
            <Aircrafts></Aircrafts>
            <BookNow ></BookNow>
            <Footer></Footer>
        </>
    )
}

export default HomePage;