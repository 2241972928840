import React from "react";
import img1 from "../../../assets/imgs/aboutus/pic1.png"
import img2 from "../../../assets/imgs/aboutus/pic2.png"
import img3 from "../../../assets/imgs/aboutus/pic3.png"
import insta from "../../../assets/imgs/aboutus/social/Instagram - Negative.svg"
import tiktok from "../../../assets/imgs/aboutus/social/TikTok - Negative.svg"
import snap from "../../../assets/imgs/aboutus/social/Snapchat - Negative.svg"

 import "animate.css/animate.min.css";  
 import { AnimationOnScroll } from'react-animation-on-scroll'; 

import './aboutUs.css'
import { useNavigate } from "react-router-dom";


const AboutUs = () => {
    let navigate = useNavigate()

    const goIg = () => {
        window.open('https://www.instagram.com/fly.with.luis')
    }
    const goWhats = () => {
        window.open('https://api.whatsapp.com/send?phone=17865027999')

    }

    const goTik = () => {
        window.open('https://www.tiktok.com/@flywithluis');
 
    }


return(
    <>
    <AnimationOnScroll animateIn="animate__fadeIn">
    <div id="aboutUs" className="aboutUs-wrapper">
        <div className="aboutUs-text-wrapper">
            <h1 className="h1-aboutUs"> About </h1>
            <h1 className="h1-aboutUs-outlined">  us</h1>
        </div>
        <div className="aboutUs-text-wrapper">
            <h2 className="h2-aboutUs">At the Elite Travel Agency our client's
             needs will always come first. Our belief is that our clients deserve a highly personalized, uniquely tailored
             experience of luxury and convenience when traveling with us.  </h2>
        </div>
        
                <AnimationOnScroll  className="img-wrapper" animateIn="animate__fadeIn">             
            <img src={img1} alt='?' className="aboutus-img"></img>
            <img src={img2} alt='?' className="aboutus-img"></img>
            <img src={img3} alt='?' className="aboutus-img"></img>
            </AnimationOnScroll>
        
        <div className="aboutUs-text-wrapper">
            <h2 className="h2-aboutUs">Our clients understand the value that ETA Jets provides; knowing that we will show them competitive pricing and private jets with 
            transparency and an attention to detail that can not be matched.   </h2>
        </div>
        <div className="social-wrapper"> 
                <AnimationOnScroll className="social-wrapper" animateIn="animate__fadeIn">          
        <button  className="social-button" onClick={goWhats}><img className="social-img" src={snap} alt='?'></img></button>
            <button  className="social-button" onClick={goTik}><img className="social-img" src={tiktok} alt='?'></img></button>
            <button  className="social-button" onClick={goIg }><img className="social-img" src={insta} alt='?'></img></button>
            </AnimationOnScroll>
        </div>


    </div>
    </AnimationOnScroll>
    </>
)
}

export default AboutUs