import { React, useState } from "react";

import "./RoundTrip.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete from "react-places-autocomplete";

import emailjs from "@emailjs/browser";

const RoundTripForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [Passengers, setPassengers] = useState(0);
  const [JetCat, setJetCat] = useState("Jet Category...");

  const [startDateReturn, setStartDateReturn] = useState(new Date());
  const [PassengersReturn, setPassengersReturn] = useState(0);
  const [JetCatReturn, setJetCatReturn] = useState("Jet Category...");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [modalTrip, setModaTrip] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const sendEmail = () => {
    const templateParams = {
      name: name,
      email: email,
      tlf: telephone,
      date: startDate,
      passengers: Passengers,
      JetCat: JetCat,
      from: from,
      to: to,
      dateReturn: startDateReturn,
      passengersReturn: PassengersReturn,
      JetCatReturn: JetCatReturn,
    };

    //    emailjs.send('service_2uvcbvg','template_o0hcy6d', templateParams, 'F1v0hsdsxaE9dI5Ki')
    // .then((response) => {
    //    console.log('SUCCESS!', response.status, response.text);
    setModaTrip(1);
    // }, (err) => {
    //    console.log('FAILED...', err);
    // });
  };

  const searchOptions = {
    types: ["airport"],
  };

  const handleSelectFrom = async (value) => {
    setFrom(value);
  };

  const handleSelectTo = async (value) => {
    setTo(value);
  };

  return (
    <>
      {modalTrip === 0 && (
        <div className="modal-bg">
          <h1 className="h1-orange"> Customer Details</h1>
          <div className="iterations-two">
            <input
              className="input-form"
              placeHolder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="input-form"
              placeHolder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="input-form"
              placeHolder="Phone Number"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
            <button className="submit" onClick={sendEmail}>
              Continue
            </button>
            <button className="call-to-action" onClick={(e) => setModaTrip(1)}>
              Close
            </button>
          </div>
        </div>
      )}
      <div className="form-wrapper">
        <h3 className="p-aircrafts">Trip details</h3>
        <PlacesAutocomplete
          value={from}
          onChange={setFrom}
          onSelect={handleSelectFrom}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="input-wrapper">
              <input
                className="input-form"
                {...getInputProps({ placeholder: "Type address" })}
                placeholder="From:"
              ></input>

              <div>
                {loading ? <h2 className="h2-orange">...loading</h2> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: "#1E3851",
                    color: suggestion.active ? "#FFC961" : "#EBE9E5",
                    cursor: "pointer",
                    padding: "5px",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "xx-small",
                  };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <PlacesAutocomplete
          value={to}
          onChange={setTo}
          onSelect={handleSelectTo}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="input-wrapper">
              <input
                className="input-form"
                {...getInputProps({ placeholder: "Type address" })}
                placeholder="To:"
              ></input>

              <div>
                {loading ? <h2 className="h2-orange">...loading</h2> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: "#1E3851",
                    color: suggestion.active ? "#FFC961" : "#EBE9E5",
                    cursor: "pointer",
                    padding: "5px",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "xx-small",
                  };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <DatePicker
          className="input-form"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        ></DatePicker>
        <h3 className="p-aircrafts">Jet category and passengers</h3>
        <div className="input-and-buttons">
          <div className="dropdown">
            <button className="input-form-jet">{JetCat}</button>
            <div className="dropdown-content">
              <button
                onClick={() => {
                  setJetCat("Turbo Prop");
                }}
              >
                Turbo Props.
              </button>
              <button
                onClick={() => {
                  setJetCat("Light Jet ");
                }}
              >
                Light Jets.
              </button>
              <button
                onClick={() => {
                  setJetCat("Midsize Jets");
                }}
              >
                Midsize Jets.
              </button>
              <button
                onClick={() => {
                  setJetCat("Super Mid Jets.");
                }}
              >
                Super Mid Jets.
              </button>
              <button
                onClick={() => {
                  setJetCat("Heavy Jets");
                }}
              >
                Heavy Jets.
              </button>
              <button
                onClick={() => {
                  setJetCat("Ultra Long Range");
                }}
              >
                Ultra Long Range.
              </button>
              <button
                onClick={() => {
                  setJetCat("VIP Airliners.");
                }}
              >
                VIP Airliners.
              </button>
            </div>
          </div>
          <button
            className="input-button"
            onClick={() => {
              if (Passengers === 0) {
                setPassengers(0);
              } else {
                setPassengers(Passengers - 1);
              }
            }}
          >
            -
          </button>
          <input
            className="input-form-passengers"
            placeholder="Passengers"
            value={Passengers}
            onChange={setPassengers}
          ></input>
          <button
            className="input-button"
            onClick={() => setPassengers(Passengers + 1)}
          >
            +
          </button>
        </div>
        <h2 className="p-aircrafts">Return</h2>

        <DatePicker
          className="input-form"
          selected={startDateReturn}
          onChange={(date) => setStartDateReturn(date)}
        ></DatePicker>
        <h3 className="p-aircrafts">Jet category and passengers</h3>
        <div className="input-and-buttons">
          <div className="dropdown">
            <button className="input-form-jet">{JetCatReturn}</button>
            <div className="dropdown-content">
              <button
                onClick={() => {
                  setJetCatReturn("Turbo Prop");
                }}
              >
                Turbo Props.
              </button>
              <button
                onClick={() => {
                  setJetCatReturn("Light Jet ");
                }}
              >
                Light Jets.
              </button>
              <button
                onClick={() => {
                  setJetCatReturn("Midsize Jets");
                }}
              >
                Midsize Jets.
              </button>
              <button
                onClick={() => {
                  setJetCatReturn("Super Mid Jets.");
                }}
              >
                Super Mid Jets.
              </button>
              <button
                onClick={() => {
                  setJetCatReturn("Heavy Jets");
                }}
              >
                Heavy Jets.
              </button>
              <button
                onClick={() => {
                  setJetCatReturn("Ultra Long Range");
                }}
              >
                Ultra Long Range.
              </button>
              <button
                onClick={() => {
                  setJetCatReturn("VIP Airliners.");
                }}
              >
                VIP Airliners.
              </button>
            </div>
          </div>
          <button
            className="input-button"
            onClick={() => {
              if (PassengersReturn === 0) {
                setPassengersReturn(0);
              } else {
                setPassengersReturn(PassengersReturn - 1);
              }
            }}
          >
            -
          </button>
          <input
            className="input-form-passengers"
            placeholder="Passengers"
            value={PassengersReturn}
            onChange={setPassengersReturn}
          ></input>
          <button
            className="input-button"
            onClick={() => setPassengersReturn(PassengersReturn + 1)}
          >
            +
          </button>
        </div>

        <button className="submit" onClick={() => setModaTrip(0)}>
          Continue
        </button>
      </div>
    </>
  );
};

export default RoundTripForm;
