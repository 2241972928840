import logo from './logo.svg';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage';

function App() {

  return (
    <>
   <BrowserRouter>
    <Routes>
      <Route exact path='/' element = {<HomePage/>}/>
    </Routes>
   </BrowserRouter>
    
    </>
  
  );
}

export default App;
