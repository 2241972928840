import React from "react";
import './footer.css'
import tiktok from '../../../assets/imgs/aboutus/social/TikTok - Negative.svg'
import snapchat from '../../../assets/imgs/aboutus/social/Snapchat - Negative.svg'
import instagram from '../../../assets/imgs/aboutus/social/Instagram - Negative.svg'
import Logo from '../../../assets/Logo2.svg'

const Footer = () => {

    const goIg = () => {
        window.open('https://www.instagram.com/fly.with.luis')
    }
    const goWhats = () => {
        window.open('https://api.whatsapp.com/send?phone=17865027999')

    }

    const goTik = () => {
        window.open('https://www.tiktok.com/@flywithluis');
 
    }
    return (
        <div className="footer-horizontal-wrapper">
            <div className="footer-wrapper">
            <div className="logo-wrapper">
                    <img className="footer-logo" src={Logo} alt='logo'/> 
                
                </div >
                <div className="fsocial-wrapper">
                    <button className="fbutton-social" onClick={goTik}>
                        <img className="social-img-footer" src={tiktok} alt='?'></img>
                    </button>
                    <button className="fbutton-social" onClick={goWhats}>
                        <img  className="social-img-footer" src={snapchat} alt='?'></img>
                    </button>
                    <button className="fbutton-social" onClick={goIg}>
                        <img   className="social-img-footer"src={instagram} alt='?'></img>
                    </button>

                </div>
              

            </div>
            <div>
                <p className="p-footer">All rights reserved. LMR AVIATION LLC.</p>
            </div>

        </div>

    )
}
export default Footer