import React from "react";
import '../aircrafts.css'
import turbo1 from  '../../../../assets/imgs/aircrafts/turbo1.png'

import TP1 from  '../../../../assets/imgs/aircrafts/small/TP1.png'
import TP2 from  '../../../../assets/imgs/aircrafts/small/TP2.png'
import TP3 from  '../../../../assets/imgs/aircrafts/small/TP3.png'

import LJ1 from  '../../../../assets/imgs/aircrafts/small/LJ1.png'
import LJ2 from  '../../../../assets/imgs/aircrafts/small/LJ2.png'
import LJ3 from  '../../../../assets/imgs/aircrafts/small/LJ3.png'

import AnchorLink from "react-anchor-link-smooth-scroll";


const Small = () => {
    return (
        <>
        <div className="component-wrapper">
            <div   className="tag">
                <div className="rectangle-1"> </div>
                <p className="label-tag">Entry-level</p>

            </div>

            <h2 className="h2-aircrafts">Turbo props.</h2>
            <p className="p-aircrafts">A turboprop is an aircraft with 
            a propeller that’s driven by a turbine engine.
             All our turboprops are comfortable, convenient and perfect 
             for landing on short runways.
            </p>
            <div className="aircrafts-img-wrapper">
                <img src={TP1} className= 'aircrafts-img' alt="?" s></img>
                <img src={TP2} className= 'aircrafts-img' alt="?" s></img>
                <img src={TP3} className= 'aircrafts-img' alt="?" s></img>
            </div>
            <div className="micro-details">
                <p className="p-aircrafts">Seats: 6-8 passengers.</p>
                <p className="p-aircrafts">Range: Up to 3 hours </p>
                <p className="p-aircrafts">Models: Pilatus PC-12, King Air 350, 
                King Air 200, Cessna Caravan, and more. </p>
            </div>
            <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>

        </div>
         <div className="component-wrapper">
         <div className="tag">
             <div className="rectangle-1"> </div>
             <p className="label-tag">Entry-level</p>

         </div>

         <h2 className="h2-aircrafts">Light Jets.</h2>
         <p className="p-aircrafts"> perfect option for family and 
         business travel to regional destinations within a few hours away. Fast, 
         comfortable transport for up to 
         seven passengers.
         </p>
         <div className="aircrafts-img-wrapper">
             <img src={LJ1} className= 'aircrafts-img' alt="?" s></img>
             <img src={LJ2} className= 'aircrafts-img' alt="?" s></img>
             <img src={LJ3} className= 'aircrafts-img' alt="?" s></img>
         </div>
         <div className="micro-details">
             <p className="p-aircrafts">Seats: 4-7 passengers.</p>
             <p className="p-aircrafts">Range: 3-3.5 hours nonstop</p>
             <p className="p-aircrafts">Models: Citation CJ2, Citation CJ3,
             Hawker 400XP, 
             Phenom 300, Lear 35, Lear 40 and more. </p>
         </div>
         <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>

     </div>
     </>
    )
}
export default Small