import { React, useState } from "react";
import "./MultiLeg.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete from "react-places-autocomplete";

import emailjs from "@emailjs/browser";

const MultiLegForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [Passengers, setPassengers] = useState(0);
  const [JetCat, setJetCat] = useState("Jet Category...");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [preLegs, setPreLegs] = useState([]);
  const [legNumber, setLegNumbers] = useState(0);

  const [modalTrip, setModaTrip] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const sendEmail = () => {
    const templateParams = {
      name: name,
      email: email,
      tlf: telephone,
      date: startDate,
      passengers: Passengers,
      JetCat: JetCat,
      from: from,
      to: to,
    };

    //    emailjs.send('service_2uvcbvg','template_e4asd8q', templateParams, 'F1v0hsdsxaE9dI5Ki')
    // .then((response) => {
    //    console.log('SUCCESS!', response.status, response.text);
    setModaTrip(1);
    // }, (err) => {
    //    console.log('FAILED...', err);
    // });
  };

  let myObj = {
    id: legNumber,
    from: from,
    to: to,
    date: startDate.toString(),
    jetCat: JetCat,
    Passengers: Passengers,
  };

  const addNewLeg = () => {
    setLegNumbers(legNumber + 1);
    let currentLegList = myObj;
    let LegList = [...preLegs, currentLegList];
    setPreLegs(LegList);

    setFrom("");
    setTo("");
    setPassengers(1);
    setJetCat("jet Category...");
    setStartDate(new Date());
  };

  const removeLeg = (id) => {
    const newLegList = preLegs.filter((leg) => leg.id !== id);
    setLegNumbers(legNumber - 1);
    setPreLegs(newLegList);
  };
  //    const  myobj =
  //     {
  //     startDate: startDate,
  //     Passengers: {Passengers},
  //     JetCat: {JetCat},
  //     from: from,
  //     to:{to},
  //    }

  //     const [legs, setlLegs] = useState([

  //         {
  //             startDate:  '',
  //             Passengers:'' ,
  //             JetCat: '',
  //             from:'',
  //             to:'',

  //         }
  //     ])
  //     const test = () => {

  //         console.log(legs)
  //     }
  //     const addLeg = ()=> {

  //         setlLegs(

  //             [
  //                 ...legs,

  //                  { startDate: startDate,
  //                  Passengers: startDate,
  //                  JetCat: JetCat,
  //                  from:from,
  //                  to:to,
  //                 }
  //             ]
  //         )
  //         test()
  //     }

  //{legs.map((leg) =>
  //key={legs.indexOf(leg)}

  const searchOptions = {
    types: ["airport"],
  };

  const handleSelect = async (value) => {
    setFrom(value);
  };

  const handleChange = async (value) => {
    setFrom(value);
  };

  const handleSelect2 = async (value) => {
    setTo(value);
  };

  // setlLegs(
  //     legs.filter(leg => legs.indexOf(leg) === (legs.length))
  // );

  return (
    <div>
      {modalTrip === 0 && (
        <div className="modal-bg">
          <h1 className="h1-orange"> Customer Details</h1>
          <div className="iterations-two">
            <input
              className="input-form"
              placeHolder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="input-form"
              placeHolder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="input-form"
              placeHolder="Phone Number"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
            <button className="submit" onClick={sendEmail}>
              Continue
            </button>
            <button className="submit" onClick={(e) => setModaTrip(1)}>
              Close
            </button>
          </div>
        </div>
      )}

      <div className="form-wrapper">
        <div className="iterations">
          <h2 className="h2-orange">Trip details</h2>
          <PlacesAutocomplete
            value={from}
            onChange={setFrom}
            onSelect={handleSelect}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="input-wrapper">
                <input
                  className="input-form"
                  {...getInputProps({ placeholder: "Type address" })}
                  placeholder="From:"
                ></input>

                <div>
                  {loading ? <h2 className="h2-orange">...loading</h2> : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: "#1E3851",
                      color: suggestion.active ? "#FFC961" : "#EBE9E5",
                      cursor: "pointer",
                      padding: "5px",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "xx-small",
                    };

                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <PlacesAutocomplete
            value={to}
            onChange={setTo}
            onSelect={handleSelect2}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="input-wrapper">
                <input
                  className="input-form"
                  {...getInputProps({ placeholder: "Type address" })}
                  placeholder="To:"
                ></input>

                <div>
                  {loading ? <h2 className="h2-orange">...loading</h2> : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: "#1E3851",
                      color: suggestion.active ? "#FFC961" : "#EBE9E5",
                      cursor: "pointer",
                      padding: "5px",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "xx-small",
                    };

                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <DatePicker
            showTimeSelect
            className="input-form"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          ></DatePicker>

          <h3>Jet category and passengers</h3>
          <div className="input-and-buttons">
            <div className="dropdown">
              <button className="input-form-jet">{JetCat}</button>
              <div className="dropdown-content">
                <button
                  onClick={() => {
                    setJetCat("Turbo Prop");
                  }}
                >
                  Turbo Props.
                </button>
                <button
                  onClick={() => {
                    setJetCat("Light Jet ");
                  }}
                >
                  Light Jets.
                </button>
                <button
                  onClick={() => {
                    setJetCat("Midsize Jets");
                  }}
                >
                  Midsize Jets.
                </button>
                <button
                  onClick={() => {
                    setJetCat("Super Mid Jets.");
                  }}
                >
                  Super Mid Jets.
                </button>
                <button
                  onClick={() => {
                    setJetCat("Heavy Jets");
                  }}
                >
                  Heavy Jets.
                </button>
                <button
                  onClick={() => {
                    setJetCat("Ultra Long Range");
                  }}
                >
                  Ultra Long Range.
                </button>
                <button
                  onClick={() => {
                    setJetCat("VIP Airliners.");
                  }}
                >
                  VIP Airliners.
                </button>
              </div>
            </div>
            <button
              className="input-button"
              onClick={() => {
                if (Passengers === 0) {
                  setPassengers(0);
                } else {
                  setPassengers(Passengers - 1);
                }
              }}
            >
              -
            </button>
            <input
              className="input-form-passengers"
              placeholder="Passengers"
              value={Passengers}
              onChange={setPassengers}
            ></input>
            <button
              className="input-button"
              onClick={() => setPassengers(Passengers + 1)}
            >
              +
            </button>
          </div>

          <button className="bookNow-selector" onClick={() => addNewLeg()}>
            {" "}
            + ADD LEG
          </button>
        </div>
      </div>

      {preLegs.map((leg) => (
        <div className="iterations-two" key={leg.id}>
          <h3>Leg - {leg.id + 1}</h3>
          <input className="input-form" value={leg.from} />
          <input className="input-form" value={leg.to} />
          <input className="input-form" value={leg.date} />

          <div className="input-and-buttons">
            <button className="input-form-jet-2">{leg.jetCat}</button>
            <input
              className="input-form-passengers"
              placeholder="Passengers"
              value={leg.Passengers}
            ></input>
          </div>

          <button
            className="bookNow-selector"
            onClick={() => removeLeg(leg.id)}
          >
            {" "}
            + REMOVE LEG
          </button>
        </div>
      ))}

      <button className="submit" onClick={() => setModaTrip(0)}>
        Continue
      </button>
    </div>
  );
};

export default MultiLegForm;
