import React from "react";
import '../aircrafts.css'
import turbo1 from  '../../../../assets/imgs/aircrafts/turbo1.png'

import MS1 from  '../../../../assets/imgs/aircrafts/medium/MS1.png'
import MS2 from  '../../../../assets/imgs/aircrafts/medium/MS2.png'
import MS3 from  '../../../../assets/imgs/aircrafts/medium/MS3.png'

import SMS1 from  '../../../../assets/imgs/aircrafts/medium/SMS1.png'
import SMS2 from  '../../../../assets/imgs/aircrafts/medium/SMS2.png'
import SMS3 from  '../../../../assets/imgs/aircrafts/medium/SMS3.png'

import AnchorLink from "react-anchor-link-smooth-scroll";

const Medium = () => {
    return (
        <>
        <div className="component-wrapper">
            <div id ='aircrafts2' className="tag">
                <div className="rectangle-2"> </div>
                <p className="label-tag">Medium</p>

            </div>

            <h2 className="h2-aircrafts">Midsize Jets.</h2>
            <p className="p-aircrafts">Aircraft from the Midsize category offer
             6 to 8 seats and a maximum range of 4’700km or 4 to 5 hours’ flight time 
             (which fluctuates based on the number of passengers onboard). 
            These cabins can connect Paris to Athens or London to Moscow.
            </p>
            <div className="aircrafts-img-wrapper">
                <img src={MS1} className= 'aircrafts-img' alt="?" s></img>
                <img src={MS2} className= 'aircrafts-img' alt="?" s></img>
                <img src={MS3} className= 'aircrafts-img' alt="?" s></img>
            </div>
            <div className="micro-details">
                <p className="p-aircrafts">Seats: 6-8 passengers.</p>
                <p className="p-aircrafts">Range: 4.5-5 hours nonstop </p>
                <p className="p-aircrafts">Models:Citation Excel, Citation XLS, Hawker 800XP,
                 Hawker 900XP,
                 Lear 60,   Gulfstream  G100,  Gulfstream  G150 and more. </p>
            </div>
            <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>

        </div>
         <div className="component-wrapper">
         <div className="tag">
             <div className="rectangle-2"> </div>
             <p className="label-tag">Medium</p>

         </div>

         <h2 className="h2-aircrafts">Super Mid Jets.</h2>
         <p className="p-aircrafts">Super Midsize Jets offer 7 to 9 seats depending 
         on the configuration and type of aircraft. Jets in that category exceed the 
         maximum range of Midsize jets and can travel up to 
         6,200 kilometers or 5 to 6 hours based on the number of passengers onboard.
         </p>
         <div className="aircrafts-img-wrapper">
             <img src={SMS1} className= 'aircrafts-img' alt="?" s></img>
             <img src={SMS2} className= 'aircrafts-img' alt="?" s></img>
             <img src={SMS3} className= 'aircrafts-img' alt="?" s></img>
         </div>
         <div className="micro-details">
             <p className="p-aircrafts">Seats: 8-9 passengers.</p>
             <p className="p-aircrafts">Range: 6.5-7 hours nonstop </p>
             <p className="p-aircrafts">Models:Citation X, Citation Sovereign, Hawker 1000, 
             Challenger 300, 
             Challenger 350, Falcon 50, Falcon 2000,  
Gulfstream  G200 and more </p>
         </div>
         <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>
     </div>
     </>
    )
}
export default Medium