import {React, useState} from "react";
import './BookNow.css'
import OneWayForm from "./OneWay";
import RoundTripForm from "./RoundTrip";
import MultiLegForm from "./MultiLeg";



const BookNow = () => {
    const [OneWay, setOneWay] = useState('bookNow-selector-selected')
    const [RoundTrip, setRoundTrip] = useState('bookNow-selector')
    const [MultiLeg, setMultiLeg] = useState('bookNow-selector')
    const [current, setCurrent] = useState(0)


    const ow = () => {
        setOneWay('bookNow-selector-selected')
        setRoundTrip('bookNow-selector')
        setMultiLeg('bookNow-selector')
        setCurrent (0)
        
    }

    const rt = () => {
        setOneWay('bookNow-selector')
        setRoundTrip('bookNow-selector-selected')
        setMultiLeg('bookNow-selector')
        setCurrent (1)

    }

    const ml = () => {
        setOneWay('bookNow-selector')
        setRoundTrip('bookNow-selector')
        setMultiLeg('bookNow-selector-selected')
        setCurrent (2)
       
    }

    
    return(
        <>
            <div className="bookNow-container" id = 'contactUs'>
                <h1 className="h1-aircrafts">Book Now!</h1>
                <h2 className="h2-aircrafts">Take Control of Your Travels.</h2>
                <div className="selector-container">
                    <button className={OneWay} onClick={ow}>One way</button>
                    
                    <button className={RoundTrip} onClick={rt}>Round-trip</button> 
                    
                    <button className={MultiLeg} onClick={ml}>Multi-leg</button> 
                </div>
                { current === 0 && 
                    <OneWayForm/>
                }
                { current === 1 && 
                    <RoundTripForm/>
                }
                { current === 2 && 
                    <MultiLegForm/>
                } 
               
            </div>
        
        </>
        )  
     

}

export default BookNow