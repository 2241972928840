import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {useRef} from 'react';
import './navBar.css'
import Logo from '../../../assets/logo.svg'


const NavBar = () => {

    const [isMenu , setMenu] = useState('mobile-menu-off')
    const [hamb, setHamb] =useState ('=')
    
    const scrollTop = () => {
        window.scrollTo(0, 0) 
        setMenu('mobile-menu-off')
        setHamb('x')
        

    }
    const closeOpen = () => {
        if(isMenu === "mobile-menu-off"){setMenu('mobile-menu'); setHamb('x')}else{setMenu('mobile-menu-off'); setHamb('=')}
    }
    //open menu
    return(
        <>
            <div className= {isMenu}>

                    <AnchorLink href="#emptyLegs" onClick={scrollTop} className="navBar-links"><button  className="navBar-links"> Empty Legs</button></AnchorLink>
                    <AnchorLink href="#aboutUs" onClick={()=> {setMenu('mobile-menu-off');setHamb('=')}} className="navBar-links"><button  className="navBar-links"> About Us</button></AnchorLink>
                    <AnchorLink href="#aircrafts"  onClick={()=> {setMenu('mobile-menu-off');setHamb('=')}}className="navBar-links"><button className="navBar-links"> Aircrafts</button></AnchorLink>
                    <AnchorLink offset = '100' href="#contactUs" onClick={()=> {setMenu('mobile-menu-off');setHamb('=')}}  className="navBar-links"> <button className="navBar-links"> Book Now</button>  </AnchorLink>
                    <button className="navBar-links" onClick={()=> {setMenu('mobile-menu-off');setHamb('=')}}> Close</button>
                    
            </div>
            
            <div className="navBar-wrapper">
                <img className="navBar-logo" src={Logo} alt='logo' onClick={scrollTop}/> 

                <div className="links-wrapper">
                    <AnchorLink href="#emptyLegs"  className="navBar-links"><button  className="navBar-links"> Empty Legs</button></AnchorLink>
                    <AnchorLink href="#aboutUs"  className="navBar-links"><button  className="navBar-links"> About Us</button></AnchorLink>
                    <AnchorLink href="#aircrafts"  className="navBar-links"><button className="navBar-links"> Aircrafts</button></AnchorLink>
                    <AnchorLink offset = '100' href="#contactUs"  className="navBar-links"> <button className="navBar-call-to-action"> Book Now</button>  </AnchorLink>
                </div>

                <button className="navBar-hamburguer" onClick={closeOpen} >{hamb}</button>
            </div>
        </>
    )
}

export default NavBar