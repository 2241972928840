import React from "react";
import '../aircrafts.css'
import turbo1 from  '../../../../assets/imgs/aircrafts/turbo1.png'

import VIP1 from  '../../../../assets/imgs/aircrafts/large/VIP1.png'
import VIP2 from  '../../../../assets/imgs/aircrafts/large/VIP2.png'
import VIP3 from  '../../../../assets/imgs/aircrafts/large/VIP3.png'

import ULR1 from  '../../../../assets/imgs/aircrafts/large/ULR1.png'
import ULR2 from  '../../../../assets/imgs/aircrafts/large/ULR2.png'
import ULR3 from  '../../../../assets/imgs/aircrafts/large/ULR3.png'


import HJ1 from  '../../../../assets/imgs/aircrafts/large/HJ1.png'
import HJ2 from  '../../../../assets/imgs/aircrafts/large/HJ2.png'
import HJ3 from  '../../../../assets/imgs/aircrafts/large/HJ3.png'



import AnchorLink from "react-anchor-link-smooth-scroll";


const Large = () => {
    return (
        <div>
     
        <div id ='aircrafts2' className="component-wrapper">
            <div  className="tag">
                <div className="rectangle-3"> </div>
                <p className="label-tag">Large</p>

            </div>

            <h2 className="h2-aircrafts">Heavy Jets.</h2>
            <p className="p-aircrafts">Heavy private jets are larger aircraft 
            offering more spacious and luxurious accommodations. Heavy jets offer a range of 
            up to 7,000 nautical miles, making them a great choice for travelers looking to
             fly a longer distance. The combination of space, luxury, speed and efficiency 
             allow you easily
             traverse continents on non-stop flights on a private jet in comfort and style.</p>
            <div className="aircrafts-img-wrapper">
                <img src={HJ1} className= 'aircrafts-img' alt="?" s></img>
                <img src={HJ2} className= 'aircrafts-img' alt="?" s></img>
                <img src={HJ3} className= 'aircrafts-img' alt="?" s></img>
            </div>
            <div className="micro-details">
                <p className="p-aircrafts">Seats: 10-18 passengers.</p>
                <p className="p-aircrafts">Range: 7-15 hours nonstop. </p>
                <p className="p-aircrafts">Models: Gulfstream G400, Gulfstream GIV, Gulfstream GV, Falcon 900, Global 500, Global Express and more.  </p>
            </div>
            <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>

        </div>
        <div className="component-wrapper">
         <div className="tag">
                <div className="rectangle-3"> </div>
                <p className="label-tag">Large</p>

            </div>
        
         <h2 className="h2-aircrafts">Ultra Long Range.</h2>
         <p className="p-aircrafts">Ultra-Long Range jets are designed for non-stop 
         inter-continental travel. Typically seating 14 or more people, these 
         aircraft sport flexible cabins configured with separate compartments for working, 
         dining, or relaxing, along with 
         top-of-the-line amenities. A flight attendant comes standard.
          </p>
         <div className="aircrafts-img-wrapper">
             <img src={ULR1} className= 'aircrafts-img' alt="?" s></img>
             <img src={ULR2} className= 'aircrafts-img' alt="?" s></img>
             <img src={ULR3} className= 'aircrafts-img' alt="?" s></img>
         </div>
         <div className="micro-details">
             <p className="p-aircrafts">Seats: 14+ passengers</p>
             <p className="p-aircrafts">Range: up to 14 hours </p>
             <p className="p-aircrafts">Models: Global 5000, Falcon 7X, Gulfstream G-650ER, and Legacy 650. and more. </p>
         </div>
         <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>

     </div>
         <div className="component-wrapper">
         <div className="tag">
                <div className="rectangle-3"> </div>
                <p className="label-tag">Large</p>

            </div>
        
         <h2 className="h2-aircrafts">VIP Airliners.</h2>
         <p className="p-aircrafts">VIP Commercial Jets such as Boeing Business Jet, Airbus VIP and other Very VIP jetliners are real apartments or offices in the sky. The VVIP Commercial Jets can carry between 19 and 50 passengers
          with a large cabin comfort space for passengers and their luggage.
          </p>
         <div className="aircrafts-img-wrapper">
             <img src={VIP1} className= 'aircrafts-img' alt="?" s></img>
             <img src={VIP2} className= 'aircrafts-img' alt="?" s></img>
             <img src={VIP3} className= 'aircrafts-img' alt="?" s></img>
         </div>
         <div className="micro-details">
             <p className="p-aircrafts">Seats: 50+ passengers</p>
             <p className="p-aircrafts">Range: 12-15 hours </p>
             <p className="p-aircrafts">Models: Boeing Business Jet, Boeing 737, Boeing 757, Airbus A320 and more. </p>
         </div>
         <AnchorLink offset = '100' href="#contactUs"> <button className="aircrafts-callToAction"> Book Now</button></AnchorLink>

     </div>
     </div>
    )
}
export default Large